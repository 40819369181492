import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()

//Base class to make API calls
export class DataService {

  apiUrl = environment.apiEndpoint;

  constructor(private http: HttpClient) {

  }

  get(action: string, params: HttpParams) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }), params: params
    };
    console.log(this.apiUrl + action);
    return this.http.get(this.apiUrl + action, options);
  }

  post(action: string, parameters: object) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    console.log(this.apiUrl);
    return this.http.post(this.apiUrl + action, JSON.stringify(parameters), options);
  }

  postform(action: string, parameters: object) {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data'
      })
    };
    console.log(parameters);
    return this.http.post(this.apiUrl + action, parameters);
  }

  getBlob<Blob>(action: string, params: HttpParams) {
    const requestOptions: Object = {
      headers: new HttpHeaders({ 'Content-Type': 'video/x-ms-wmv' }),
      responseType: 'arraybuffer',
      params: params
    }

    return this.http.get<Blob>(this.apiUrl + action, requestOptions);
  }

  exportBlob<Blob>(action: string, params: object) {
    const requestOptions: Object = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'arraybuffer'
    }

    return this.http.post<Blob>(this.apiUrl + action, JSON.stringify(params), requestOptions);
  }

  getFile<Blob>(action: string, params: object) {
    const requestOptions: Object = {
      headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
      responseType: 'arraybuffer',
      params: params
    }

    return this.http.get<Blob>(this.apiUrl + action, requestOptions);
  }

}
