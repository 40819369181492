import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../framework/base/base.component';
import { TR_ComplaintVm } from '../../../framework/viewmodels/complaint';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { extend } from 'highcharts';

@Component({
    selector: 'send-to-editor',
    templateUrl: './send-to-editor.component.html'

})

export class SendToEditorComponent extends BaseComponent implements OnInit {
    title: string;
    closeButtoneName: string;
    public modalData: TR_ComplaintVm;
    modalForm: FormGroup;
    //baseComp: BaseComponent = new BaseComponent();


    //constructor(
    //    public complaintModal: BsModalRef
    //) { }


    ngOnInit() {
        
        this.modalForm = this.formBuilder.group({
            efullName: [''],
            email: [''],
            stagMessage: [''],
            notes:['']
        });
        this.loadModal();
    }


    loadModal() {
        this.dc.post('api/Complaint/ComplaintEvent', this.modalData).subscribe((response: TR_ComplaintVm) => {
            this.IsLoading = true;
            this.modalData = response;
            console.log(this.modalData);
            this.IsLoading = false;
        },
            (error) => {
                console.log(error);
                this.somethingWentWrong()
                this.IsLoading = false;
            });
    }

    Save() {

        console.log('Saving');
        
    }
}
