import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalComponent } from '../modal/modal.component';
import { DataService } from '../service/data.service';
import { SYS_ActionResultStructResult } from '../../dbinterface/PR.Types';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../authorisation/auth.service';

export class BaseComponent {
  public IsLoading: boolean;
  public modalRef: BsModalRef;
  public modalRef2: BsModalRef;

  constructor(public dc: DataService, public auth: AuthService, public route: ActivatedRoute, public router: Router, public toastr: ToastrService, public modalService: BsModalService, public formBuilder: FormBuilder, public httpClient: HttpClient, private http: HttpClient) {

  }

  showValidation(ar: Array<SYS_ActionResultStructResult>) {

    let listString = Array<String>();

    ar.forEach((element) => {
      //If success just show the toastr message - we should technically not have duplicates or a case of trues and falses mixed together. If there is, it should be resolved in the DB proc
      if (element.IsError != true) {
        this.toastr.success(element.ResultMessage);
      }

      //If error then build an error list to show on modal popup.
      if (element.IsError == true) {
        console.error(element);
        listString.push(element.ResultMessage);
      }
    });

    //if our list string is longer than 0 then use the popup
    if (listString.length >= 1) {
      this.openModal("An error has occured", listString);
    }
  }

  somethingWentWrong() {
    let listString = Array<String>();
    listString.push("Something has gone wrong, please contact support.");
    this.openModal("Something went wrong", listString);
  }

  openModal(title, listString) {

    this.modalRef = this.modalService.show(ModalComponent, {
      initialState: {
        title: title,
        listString: listString
      }
    });
  }


  doHide() {
    this.modalRef.hide();
  }

  //WindowNavigation Url
  navigateBackUrl() {
    window.history.back();
  }


  // Reload current Viewed Page  
  reloadPage(id) {

    var urlParams = [];
    window.location.search.replace(null, null).split("").forEach(function (e, i) {

    });

    if (urlParams["loaded"]) { } else {
      let win = (window as any);
      win.location.reload(id);
    }
  }
}
