import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()

//The JWT Interceptor intercepts http requests from the application to add a JWT auth token to the Authorization header if the user is logged in.

export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // add authorization header with jwt token if available, use AUTH service
        let currentUser = JSON.parse(localStorage.getItem('user'));

        if (currentUser && currentUser.LoginResult.LoginToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `${currentUser.LoginResult.LoginToken}`
                }
            });
        }

        return next.handle(request);
    }
}
