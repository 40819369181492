import { AU_User_LoginResult, AU_ForgotPasswordResult } from "../../dbinterface/PR.Types";
import { ActionResult } from "../interfaces/system";


export class AU_ForgotPasswordVm {
    UserName: string;
    BaseUrl: string;
    EmailResult: AU_ForgotPasswordResult;
}

export class AU_LoginVm {
    LoginResult: AU_User_LoginResult;
    RoleCodes: Array<string>;

    UserName: string;
    Password: string;
}

export class AU_ResetPasswordVm {

    Password: string;
    ConfirmPassword: string;
    ForgotPasswordGuid: string;
    ActionResult: ActionResult;
}


