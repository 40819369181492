import { CanActivate, Router, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class RoleGuard implements CanActivate {
 
    constructor(public auth: AuthService, public router: Router, public toastr: ToastrService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        // this will be passed from the route config
        // on the data property
        const expectedRole = route.data.expectedRole;
        const expectedRole2 = route.data.expectedRole2;

        if (!this.auth.checkAuthRole(expectedRole)) {
            this.toastr.error("Error!!.... Contact support");
            this.router.navigate(['pages/home']);
            return false;
        } else {
            //this.toastr.success("You have access to this section")
            return true;
        }
        
    }

}
