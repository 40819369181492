import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { AU_LoginVm } from "../viewmodels/account";
import { LoginComponent } from "../../pages/account/login/login.component";

@Injectable()
export class AuthService{

    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    rememberMe: boolean = false;

  get isLoggedIn() {
        return this.loggedIn.asObservable();
    }

    constructor(private router: Router) {
        this.checkAuthStatus();
    }

    checkAuthStatus() {
        if (localStorage.getItem('user')) {
            this.loggedIn.next(true);
        }
        else {
            this.loggedIn.next(false);
        }
    }

    checkAuthRole(roleCode) {
        let vm = new AU_LoginVm();
        vm = JSON.parse(localStorage.getItem('user'));

        let isAuthenticated: boolean = false;

        // User Role  
        if (vm != null && vm.RoleCodes != null) {
            vm.RoleCodes.forEach((element) => {
                if (roleCode == element) {
                    isAuthenticated = true;
                }
            });
        }

        return isAuthenticated;
    }

    login(vm: AU_LoginVm) {
        this.loggedIn.next(true);
        localStorage.setItem('user', JSON.stringify(vm));
        this.router.navigate(['/pages/home']);
        //this.router.navigate(['/pages/complaint-list']);
    }
    
  logout() {
    if (this.rememberMe == false) {
        this.loggedIn.next(false);
        localStorage.clear();
        this.router.navigate(['/']);
    } else {
        this.loggedIn.next(false);
        this.router.navigate(['/']);
      }
        
    }
}
