import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ModalComponent } from './framework/modal/modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthService } from './framework/authorisation/auth.service';
import { JwtInterceptor } from './framework/authorisation/jwt.interceptor';
import { AuthGuard } from './framework/authorisation/auth.guard';
import { RoleGuard } from './framework/authorisation/role.guard';
import { DataService } from './framework/service/data.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SendToEditorComponent } from './pages/complaints/send-to-editor/send-to-editor.component';


@NgModule({
    declarations: [
        AppComponent,
        //LoginComponent,
        ModalComponent,
        SendToEditorComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        HttpClientModule,
        FormsModule,
        RouterModule.forRoot([
            { path: '', redirectTo: '/account/login', pathMatch: 'full' },
            { path: 'account', loadChildren: () => import('./pages/_layouts/account/account.module').then(x => x.AccountModule) },
            { path: 'pages', loadChildren: () => import('./pages/_layouts/pages/pages.module').then(x => x.PagesModule) },

        ]),
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot(),
        ModalModule.forRoot(),
        ReactiveFormsModule,
    ],
    providers: [

        AuthGuard,
        RoleGuard,
        DataService,
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        }
    ],
    entryComponents: [
        ModalComponent,
        SendToEditorComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
