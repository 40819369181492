import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
})

export class ModalComponent {

    title: string;
    listString: Array<string>;
    constructor(
        public modalRef: BsModalRef
    ) { }

    ngOnInit() {

    }
}
